import dayjs, { Dayjs } from 'dayjs';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useClient } from 'urql';

import getSearchResultsQuery from './getSearchResults.gql';
import { DynamicPackageFiltersInput, PageType } from '@AuroraTypes';
import { MONTH_FLEXIBILITY_VALUE } from '@Constants/Flexibility';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { mergeSearchParams } from '@Core/helpers/url';
import { readOrFetchQuery } from '@Core/readOrFetchQuery';
import { useNoAvailabilityTracking } from '@Core/tracking/hooks/useNoAvailabilityTracking';
import { ISO_DATE_FORMAT } from '@Dates/dates';
import { equalityFn } from '@Stores/equalityFn';
import { useSearchSelectionStore, useStoreContext } from '@Stores/StoreContext';
import { trackSearchChanges } from '@Tracking';

type StoreSubscription = () => void;

export const getDateRange = (selectedDate: Dayjs | undefined, flexibility: number) => {
  const today = dayjs().startOf('day');

  let departureDateMin = (selectedDate || today).format(ISO_DATE_FORMAT);
  let departureDateMax;

  if (flexibility && selectedDate) {
    if (flexibility === MONTH_FLEXIBILITY_VALUE) {
      departureDateMin = selectedDate.startOf('month').format(ISO_DATE_FORMAT);
      departureDateMax = selectedDate.endOf('month').format(ISO_DATE_FORMAT);
    } else {
      departureDateMin = selectedDate.subtract(flexibility, 'day').format(ISO_DATE_FORMAT);
      departureDateMax = selectedDate.add(flexibility, 'day').format(ISO_DATE_FORMAT);
    }
  } else if (selectedDate) {
    departureDateMax = selectedDate.format(ISO_DATE_FORMAT);
  } else {
    // @TODO: `departureDateMax` is mandatory, so we set something far in the future
    // until we change this to optional in SearchService.
    departureDateMax = dayjs().add(2, 'years').format('YYYY-MM-DD');
  }

  return {
    departureDateMin,
    departureDateMax,
  };
};

interface FetchAndUpdateFlightsParams {
  isInitial?: boolean;
  isPagination?: boolean;
}

interface SearchResultsStoreUpdaterProps {
  pageType: PageType;
}

// Keep these params when updating the url
const urlParamsToKeep = ['sunrise', 'lhx'];

export const SearchResultsStoreUpdater: React.FC<
  React.PropsWithChildren<SearchResultsStoreUpdaterProps>
> = ({ children, pageType }) => {
  const initialised = useRef(false);
  const subscriptions = useRef<StoreSubscription[]>();
  const urqlClient = useClient();
  const { pathname } = useLocation();
  const history = useHistory();
  const { searchAvailability, searchResults, searchSelection } = useStoreContext();
  const { savedSearchSelection } = useUserPreferences();
  const setFilterSelection = useSearchSelectionStore((state) => state.setFilterSelection);

  const sendRegularNoAvailabilityTracking = useNoAvailabilityTracking({
    action: 'search-results-page',
  });
  const sendMapNoAvailabilityTracking = useNoAvailabilityTracking({
    action: 'map-search-results',
  });

  const removeFiltersByDestinations = useCallback(
    (destinationIds: string[], filters: DynamicPackageFiltersInput) => {
      const allowedIds = new Set(destinationIds);

      const { regions = [], resorts = [] } = filters;

      const updatedRegions = regions.filter((value: string) => {
        const [regionId, countryId] = value.split('_', 2);
        if (allowedIds.has(countryId)) {
          allowedIds.add(regionId);

          return true;
        }

        return false;
      });

      const updatedResorts = resorts.filter((value: string) => {
        const regionId = value.split('_', 2)[1];

        return allowedIds.has(regionId);
      });

      if (updatedRegions.length !== regions.length || updatedResorts.length !== resorts.length) {
        setFilterSelection({
          regions: updatedRegions,
          resorts: updatedResorts,
        });
      }
    },
    [setFilterSelection],
  );

  const updateUrl = useCallback(() => {
    const { destinationIds, filters } = searchSelection.getState();
    removeFiltersByDestinations(destinationIds, filters);

    const params = mergeSearchParams(
      history.location.search,
      searchSelection.getState().toUrl(pageType),
      urlParamsToKeep,
    );

    history.replace(`${pathname}?${params}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAndUpdateResults = useCallback(
    ({ isInitial = false, isPagination = false }: FetchAndUpdateFlightsParams = {}) => {
      if (isPagination) {
        searchResults.setState({ paginating: true });
      } else if (searchSelection.getState().resultsStartIndex) {
        searchSelection.getState().setResultsStartIndex(0);
      }

      const {
        masterIds,
        pinnedMasterIds,
        destinationIds,
        departureAirports,
        nights,
        rooms,
        date,
        flexibility,
        filters,
        resultsStartIndex = 0,
        sort,
        viewMode,
      } = searchSelection.getState();

      const isListMode = viewMode === 'list';
      const resultsLimit = isListMode ? 25 : 30;

      if (isListMode) {
        searchResults.setState({ fetching: true });
      } else {
        searchResults.setState({ mapSearchFetching: true });
      }

      // update the url (on client only)
      if (typeof window !== 'undefined' && !isInitial) {
        updateUrl();
      }

      return readOrFetchQuery(
        urqlClient,
        getSearchResultsQuery,
        {
          masterIds,
          pinnedMasterIds,
          destinationIds: isListMode ? destinationIds : [],
          departureAirports,
          nights,
          rooms,
          ...getDateRange(date, flexibility),
          filters: {
            ...filters,
            location: !isListMode ? filters.location : undefined,
          },
          resultsStartIndex,
          resultsLimit,
          sort,
        },
        ({ data, error }) => {
          // When error happens set SearchResultsStore to reflect a No Availability state
          if (error) {
            searchResults.setState({
              fetching: false,
              mapSearchFetching: false,
              paginating: false,
              results: [],
              mapSearchResults: [],
              pinnedResults: [],
              totalResults: 0,
              nextPageOffset: 0,
            });

            return;
          }

          const {
            availability,
            orderedFilters,
            popularFilters,
            results,
            pinnedResults,
            nextPageOffset = 0,
          } = data?.Search.dynamicPackages!;

          const searchState = searchResults.getState();
          const baseListResults = isPagination
            ? searchState.results.concat(results.items)
            : results.items;
          const listResults = isListMode ? baseListResults : searchState.results;
          const mapSearchResults = !isListMode ? results.items : searchState.mapSearchResults;

          searchResults.setState({
            fetching: false,
            mapSearchFetching: false,
            paginating: false,
            orderedFilters,
            popularFilters,
            results: listResults,
            mapSearchResults,
            pinnedResults,
            totalResults: results.totalCount,
            nextPageOffset,
          });

          searchAvailability.setState({
            ...availability,
            // Force reset cancellation policies here to avoid invalid availability info. This
            // should get refactored once we add a cancellation filter to SRP
            cancellationPolicies: undefined,
          });

          trackSearchChanges(
            searchSelection.getState(),
            searchAvailability.getState(),
            isInitial,
            searchResults.getState(),
          );

          // Report no availability client event
          if (isListMode && !listResults.length) {
            sendRegularNoAvailabilityTracking();
          } else if (!isListMode && !mapSearchResults.length) {
            sendMapNoAvailabilityTracking();
          }
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // 1. Initialise SearchSelection store from URL params
  if (!initialised.current) {
    initialised.current = true;

    const initialFetch = fetchAndUpdateResults({
      isInitial: true,
    });

    // The initial fetch returns a promise in SSR prepass, which we need to throw for Suspense
    if (initialFetch instanceof Promise && typeof window === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw initialFetch;
    }
  }

  // 2. Subscribe search selection changes to update availability
  if (!subscriptions.current) {
    subscriptions.current = [
      // Update saved search cookie when search selection changes
      searchSelection.subscribe((state) => {
        savedSearchSelection.set(state.toCookie());
      }),

      // Any search change (excl. pagination and view mode)
      searchSelection.subscribe(
        ({ resultsStartIndex, viewMode, ...rest }) => rest,
        () => fetchAndUpdateResults(),
        { equalityFn },
      ),

      // For the case of geo-bounds-based map search, it needs to reload results when viewMode has changed from map to list
      searchSelection.subscribe(
        ({ viewMode }) => viewMode,
        (viewMode) => {
          if (viewMode === 'list') {
            fetchAndUpdateResults();
          }
        },
      ),

      // Pagination changes
      searchSelection.subscribe(
        ({ resultsStartIndex }) => resultsStartIndex,
        (value) => {
          if (value) {
            fetchAndUpdateResults({
              isPagination: true,
            });
          }
        },
      ),
    ];
  }

  // 3. Unsubscribe when component unmounts
  useEffect(
    () => () => {
      if (subscriptions.current) {
        subscriptions.current.forEach((teardown) => teardown());
      }
    },
    [],
  );

  return <Fragment>{children}</Fragment>;
};
